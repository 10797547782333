<template>
	<div class="zui-bg-white">
		<!-- 文字条 -->
		<div class="hangar_pages_top top_tap flex_row  zui-relative">
			{{ detail.brandName }}
			<div class="btnblock  header_r padding-0 col-md-1  col-sm-2 col-xs-3">
				<div v-if="!isLogin" class="zui-h-100 zui-pointer zui-flex zui-center-center log"
					style="font-size: 12px;">{{$t('login.login')}}</div>
				<div class="btnCenter">
					<span @click="_back" v-show="!isPhone"
						class="jinBtn cursor  zui-text-white back">{{$t('common.back')}}</span>
				</div>
			</div>
		</div>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="_back"
			style="position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<div style="min-height: 60px; padding: 25px" class="zui-flex content zui-flex-column"
			v-html="detail.brandContent"></div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex';
	export default {
		components: {

		},
		data() {
			return {
				isPhone: false,
				name: '湾流G20',
				detail: {},
				// 图片父容器高度
				bannerHeight: 2000,
				// 浏览器宽度
				screenWidth: 0,

			};
		},
		computed: {
			...mapState(['isLogin']),
		},
		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			} else {
				this.isPhone = false
			}
			// console.log(this.$route.params);
			let params = this.$route.params;
			if (!params.planeInfo) {
				this.$router.push({
					name: 'aircraftBrand'
				});
			}
			this.detail = params.planeInfo;
		},
		methods: {
			_back() {
				this.$router.push({
					name: 'aircraftBrand',
					params: {
						id: this.detail.id
					}
				})
			},
			setSize: function() {
				// 通过浏览器宽度(图片宽度)计算高度
				// 通过浏览器宽度(图片宽度)计算高度
				this.bannerHeight = (576 / 1920) * this.screenWidth;
				this.centerHeight = (1080 / 1920) * this.screenWidth;
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					this.bannerHeight = this.bannerHeight + 100;
				}
			},

		},
		mounted() {
			// this.login()

			// 首次加载时,需要调用一次
			this.screenWidth = window.innerWidth;
			// 窗口大小发生改变时,调用一次
			this.setSize();
			// 窗口大小发生改变时,调用一次
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
		}
	};
</script>
<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	// *{padding: 0;margin: 0;}
	// html,body{
	// 	height: 100%;
	// }
	// img{
	// 	max-width: 100%;
	// 	display: block;
	// 	height: auto;
	// }
	// a{text-decoration: none;}
	// iframe{width: 100%;}
	// p{line-height: 1.6;margin: 0px;}
	// table{
	// 	word-wrap: break-word;
	// 	word-break: break-all;
	// 	max-width: 100%;
	// 	border: none;

	// }
	// .mce-object-iframe{
	// 	width: 100%;
	// 	box-sizing: border-box;
	// 	margin: 0;
	// 	padding: 0;
	// }
	// ul,ol{list-style-position: inside;}


	.log {
		padding-right: 12px;
		// font-weight: bold;
		color: #dbb96e;
		opacity: 0;
		-webkit-user-select: none;
		cursor: default;
	}

	.btnblock {
		width: 8.333333333%;
		position: absolute;
		// height: 100px;
		right: 0;
		display: flex;
		justify-content: center;
	}

	.btnCenter {
		height: 26px;
		width: 40px;
		display: flex;
		justify-content: center;
	}

	.top_tap {
		color: $colorG;
		font-size: $fontK;
		width: 100%;
		height: 43px;
		background: url('../assets/image/17.jpg') center;
	}

	/deep/ .content {
		width: 60%;
		margin: auto;
		font-family: sans-serif;
		p{margin: 0 !important;}
	//列表元素
li{ display:list-item }
ol{list-style-type: decimal }
ol ul, ul ol,ul ul, ol ol  { margin-top: 0; margin-bottom: 0 }
a{color: -webkit-link;text-decoration: none;}
//标题 
h1{ font-size: xx-large; }
h2{ font-size: x-large;  }
h3{ font-size: large; }
h4{
	font-size: medium;
}
h4, p,blockquote, ul,fieldset, form,ol, dl, dir,menu { margin: 1.12em 0}
h5 { font-size: small;  }
h6{ font-size: x-small;  }
h1, h2, h3, h4,h5, h6{
	line-height: inherit;
	margin: 0;
	clear:both;
	
}
h1, h2, h3, h4,h5, h6, b,strong  { font-weight: bold;}
		

		img {
			max-width: 100% !important;
			height: auto;
		}
		video{
			max-width: 100% !important;
			height: auto;
		}

	}

	@media only screen and (max-width: 960px) {
		/deep/ .content {
			width: 95%;
		}
	}

	@media only screen and (max-width : 992px) {
		.btnblock {
			width: 33.3333333%;
		}
	}

	@media only screen and (max-width : 768px) {
		.btnblock {
			width: 25%;
		}
	}
</style>
